import React from 'react';
import { Button, Result, Collapse } from 'antd';
import { useNavigate } from 'react-router-dom';


export default function News() {
  return (
    <div>
        <Collapse size="small" items={[{key: '1', 
        label: <div>Aug 6th, 2024: Updating, Correct code of EHH, Job submission restriction </div>, 
        children: <div><ul>
            <li>There are runtime error for EHH code, </li>
            <li>Users are allowed to submit only two NRIMD tasks a day to prevent malicious submission of too many tasks from occupying background computing resources.</li>
        </ul></div>,
        },]}/>
        <Collapse size="small" items={[{key: '1', 
        label: <div>Aug 5th, 2024: Updated the work flow: ca trajectory -&gt; structure PDB for visualization </div>, 
        children: <div>Change old work flow (REMO -&gt; s4pred -&gt; pv) to new (pulchra -&gt; pydssp -&gt; pv), the time for users to submit jobs is significantly reduced!</div>,
        },]}/>
    </div>
  )
}


