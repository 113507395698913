
import { Collapse, Select, ConfigProvider, theme, Form, Upload, Button } from 'antd';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import example_submit from './assets/images/guide/example_submit.png'
import example_check from './assets/images/guide/example_check.png'
import example_upload_psh from './assets/images/guide/example_upload_psh.png'
import example_submit_psh from './assets/images/guide/example_submit_psh.png'
import result_nri_res from "./assets/images/guide/result_nri_res.png"
import result_nri_domain from "./assets/images/guide/result_nri_domain.png"
import result_nri_path from "./assets/images/guide/result_nri_path.png"
import result_nri_res_psh from "./assets/images/guide/result_nri_res.png"
import result_nri_domain_psh from "./assets/images/guide/result_nri_domain.png"
import result_nri_path_psh from "./assets/images/guide/result_nri_path_psh.png"

import upload_5lox from "./assets/images/guide/upload_5lox.png"
import result_nri_5loxapo from "./assets/images/guide/result_5loxapo.png"
import result_nri_5loxakba from "./assets/images/guide/result_5loxakba.png"


import all_paths from "./assets/images/guide/all_paths.png"
import PV from './components/result_components/PV'
import React, { useEffect, useState } from 'react'
const text_font_color = "rgba(17, 34, 100)"


const text_font = { fontSize:"2vh", fontWeight:"bold", color:"balck"}


const Example = () => {
  const [example1StrucPDBPath, setExample1StrucPDBPath] = useState('sod1.pdb')
  const [example2StrucPDBPath, setExample2StrucPDBPath] = useState('PSH.pdb')
  const [ifStruc1, setIfStruc1] = useState(true)
  const [ifStruc2, setIfStruc2] = useState(true)
  const { token } = theme.useToken();

  const UploadStrucPDB1 = () => {
    const [strucFileList, setStrucFileList] = useState([]);
    const strucHandleChange = (info) => {
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);
      newFileList = newFileList.map((file) => {
        if (file.response) {
          console.log(file.response);
          setExample1StrucPDBPath(file.response.StrucPDBPath)
          setIfStruc1(true)
        }
        return file;
      });
      setStrucFileList(newFileList);
    };

    const strucProps={action:'/api/nrimd/submit/upload_strucpdb/',onChange:strucHandleChange,multiple:false}
  
    return (
      <>
      <Form name="complex-form" labelCol={{span: 6,}} wrapperCol={{span: 18,}}>
            <Form.Item name="StrucFile" label="PDB File" extra="optional, upload protein structure pdb file (include all atoms) can visualize long-range allosteric interactions in protein " >
              <Upload {...strucProps} fileList={strucFileList}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
      </Form>
      <div style={{textAlign:'center'}}><Button type="primary" onClick={()=>{setIfStruc1(true)}} icon={<CloseOutlined />}>Cancel</Button></div>
      </>
    );
  };

  const UploadStrucPDB2 = () => {
    const [strucFileList, setStrucFileList] = useState([]);
    const strucHandleChange = (info) => {
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);
      newFileList = newFileList.map((file) => {
        if (file.response) {
          console.log(file.response);
          setExample2StrucPDBPath(file.response.StrucPDBPath)
          setIfStruc2(true)
        }
        return file;
      });
      setStrucFileList(newFileList);
    };

    const strucProps={action:'/api/nrimd/submit/upload_strucpdb/',onChange:strucHandleChange,multiple:false}
  
    return (
      <>
      <Form name="complex-form" labelCol={{span: 6,}} wrapperCol={{span: 18,}}>
            <Form.Item name="StrucFile" label="PDB File" extra="optional, upload protein structure pdb file (include all atoms) can visualize long-range allosteric interactions in protein " >
              <Upload {...strucProps} fileList={strucFileList}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
      </Form>
      <div style={{textAlign:'center'}}><Button type="primary" onClick={()=>{setIfStruc2(true)}} icon={<CloseOutlined />}>Cancel</Button></div>
      </>
    );
  };
  const panelStyle = {
    marginBottom: "0.5vh",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
  };
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: '1',
      label: 'Example 1: Copper-zinc superoxide dismutase-1 (SOD1, 153 residues)',
      children: <>
      <div style={{fontWeight:"bold"}}>Introduction</div>
      <ul>
        <li>
        Copper-zinc superoxide dismutase-1 (SOD1) is an oxidoreductase responsible for decomposing toxic superoxide 
        radicals into molecular oxygen and hydrogen peroxide in two rapid steps by alternately reducing and oxidizing active-site copper26. 
        </li>
        <li>
        A study of the SOD1-linked neurodegenerative disorder amyotrophic lateral sclerosis (ALS) shows that the G93A mutation
        forces the EL to move away from ZL, decreasing the Zn (II) affinity of the protein, which affects the pathogenic process of the SOD1-linked ALS29.
        </li>
        <li>
        Since the G93A mutation occurs away from the metal site, this process is allosteric.Since the G93A mutation occurs away from the metal site, this process is allosteric.        </li>
        <li>
        Next, let's perform NRIMD analysis of the molecular dynamics simulation trajectory of G93A mutant!
        </li>
      </ul>

      <div style={{fontWeight:"bold"}}>1. Get the correct input</div>
      <ul>
        <li>
          Example trajectory were built by Amber 22, you can download it <a href={'/api/nrimd/download/download_sod_catraj/'}>here</a>.
        </li>
        <li>
          If you want upload your trajectory, please refer the User Guide section.
        </li>
      </ul>
      <div style={{fontWeight:"bold"}}>2. Submit the example trajectory</div>
      <div>You can follow the pictures below to submit the example job on submit page</div>
      <img src={example_submit} style={{border:'1px solid #000', width:"60%", display:"block"}}></img>
      <div>We will give provide you with a job ID, please record it!</div>
      <div style={{fontWeight:"bold"}}>3. Get the result</div>
      <div>You can check the job status on Get Result page:</div>
      <img src={example_check} style={{border:'1px solid #000', width:"60%", display:"block"}}></img>
      <div>For the example, results will be displayed directly !</div>
      <div> In the results genereated below, both rows and columns represent residues of the input Carbon-Alpha skeleton. This heatmap demonstrates the inferred interactions between these residues from the NRIMD model. 
        The colors indicate the strength of the interaction: dark colors mean strong interaction, while lighter colors indicate weaker ones. Users can tune the parameters below to select a customerized threshold.</div>
        <img src={result_nri_res} style={{width:"60%", border:"1px solid gray"}}></img>
        <div> Comparing to the heatmap on interactions between residues genereated above, 
          users can manually define the domains below to obtain a coarse-grained heatmap between the domains.</div>
        <img src={result_nri_domain} style={{width:"60%", border:"1px solid gray"}}></img>
        <div>In this section, the user can obtain paths from the allosteric source residue to the allosteric target residue, 
          and also reset and get real-time result. The pathways can be visualized on the left, you can also set protein and pathway styles to create beautiful figures.</div>
          <div>For example, residue 93 is allosteric site and residue 134 is active site, we have chosen all pathways in the protein and set a  beautiful style: </div>
        <img src={result_nri_path} style={{width:"60%", border:"1px solid gray"}}></img>
        

        <div>We offer a free test to visualize allosteric paths in protein.</div>
        <div style={{width:"50%"}}>
        {
          ifStruc1
          ?
          <PV StrucPDBPath = {example1StrucPDBPath} setIfStruc={setIfStruc1} method="example_sod1"/>
          :
          <div style={{height:'50vh',padding:"10%",border:"2px dashed lightblue"}}><UploadStrucPDB1 /></div>
           
        }
        
      </div>

      </>,
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Example 2: Presenilin/SPP Homologue (PSH, 304 residues) ',
      children: <>
      <div style={{fontWeight:"bold"}}>Introduction</div>
      <ul>
        <li>
        Presenilin/SPP Homologue (PSH) is an intramembrane aspartyl protease that regulates important biological functions in eukaryotes and it contains 9 transmembrane (TM) domains (TM 1-9).
        </li>
        <li>
        A study on PSH identify three mutations, V151E and L155S in TM6, and S225I in TM7, which  may affect active sites Asp 162 and Asp 220 through altered local conformation.
        </li>
        <li>
        For a far-reaching exploration, we can use NRIMD to uncover the allosteric interaction between mutate site and active sites.
        </li>
      </ul>

      <div style={{fontWeight:"bold"}}>1. Get the correct input</div>
      <ul>
        <li>
          Example trajectory were built by Amber 22, you can download it <a href={'/api/nrimd/download/download_psh_catraj/'}>here</a>.
        </li>
        <li>
          Due to the capacity of the available hardware, for protein lenger larger than 300 residues, we will implement one of the sampling strategy to compute  the trajecory using NRIMD. So 
          Let's proceed!
        </li>
      </ul>
      <div style={{fontWeight:"bold"}}>2. Submit the example trajectory</div>
      <div>Follow the pictures below to submit the PSH example job on Submit Job page</div>
      <div>Firt you should upload trajecory file PSH_ca_traj.zip and upload it:</div>
      <img src={example_upload_psh} style={{border:'1px solid #000', width:"40%", display:"block"}}></img>
      <div>Next you will receive a force sampling tip, close it and choose one sampling method, here we choose first sampling strategy and submit the job.</div>
      <img src={example_submit_psh} style={{border:'1px solid #000', width:"30%", display:"block"}}></img>
      <div>Typically, NRIMD then will issue a job ID and users must wait for the job to finish. However for example PSH, NRIMD will quickly provide results.</div>
      <div style={{fontWeight:"bold"}}>3. Get the result</div>
      <div>Similar to the SOD1 example, users can check the job status on the Get Result page using the Job ID or bookmark with https://nrimd.luddy.iupui.edu/result/+JobID.</div>
      <div>Finally, let's analyze the results of PSH.</div>
      <div> In the results genereated below,
        the left heatmap indicates the inferred interactions between residues from the NRIMD model. The colors indicate the strength of the interaction: dark colors mean stronger interaction, while lighter colors indicate weaker ones. User can adjust the parameters below to select customized threshold.</div>
        <img src={result_nri_res_psh} style={{width:"60%", border:"1px solid gray"}}></img>
        <div> Compared to the heatmap of interactions between residues genereated above, 
          users can manually define the domains below to get the coarse grained heatmap between the domains.</div>
        <img src={result_nri_domain_psh} style={{width:"60%", border:"1px solid gray"}}></img>
        <div>In this section, users can obtain the paths from the allosteric source residue to the allosteric target residue, and can reset it to obtain real-time results. The pathways can be visualized on the left, users can also set protein and pathway style to create beautiful figures.</div>
          <div>For example, residue 151 (noted as 148 due to the  protein missing the first 3 residues) is allosteric site and residue 220 (noted as  217) are the active sites, we have chosen all pathways in the protein and set a  beautiful style: </div>
        <img src={result_nri_path_psh} style={{width:"60%", border:"1px solid gray"}}></img>
        

        <div>We provide a free test to visualize allosteric paths in PSH, user can set allosteric path or desired protein style here.</div>
        <div style={{width:"50%"}}>
        {
          ifStruc2
          ?
          <PV StrucPDBPath = {example2StrucPDBPath} setIfStruc={setIfStruc2} method="example_psh"/>
          :
          <div style={{height:'50vh',padding:"10%",border:"2px dashed lightblue"}}><UploadStrucPDB2 /></div>
           
        }
      </div>

      </>,
      style: panelStyle,
    },
    {
      key: '3',
      label: 'Example 3: 5-lipoxygenase (5LOX, 670 residues) ',
      children: <>
      <div style={{fontWeight:"bold"}}>Introduction</div>
      <ul>
        <li>
        5-lipoxygenase (5LOX) contains two structural domains, the N-terminal regulatory domain and the C-terminal catalytic domain. It catalyzes the first two reactions in the biosynthesis of leukotrienes, which are potent mediators involved in inflammation and allergic reactions.
        </li>
        <li>
        AKBA is a well-known allosteric inhibitor of 5LOX, and can affect the active sites F177 and Y181 from the allosteric sites.
        </li>
        <li>
        In this example, we constructed  two systems (5LOX and 5LOX-AKBA) by AMBER22. Through NRIMD analysis of 300nm trajectories for both 5LOX and 5LOX containing the inhibitor AKBA, we explore AKBA how influences the active sites F177 and Y181 from bindign site.
        </li>
      </ul>

      <div style={{fontWeight:"bold"}}>1. Get the inputs</div>
      <ul>
        <li>
          Example trajectories were built by Amber 22. You can download them at <a href={'/api/nrimd/download/download_5loxapo_catraj/'}>5LOX</a> and <a href={'/api/nrimd/download/download_5loxakba_catraj/'}>5LOX-AKBA</a>.
        </li>
        <li>
          Due to the capacity of the available hardware, for proteins longer than 300 residues, we will implement one of the sampling strategy to compute the trajecory using NRIMD. So proceed it!
        </li>
      </ul>
      <div style={{fontWeight:"bold"}}>2. Submit the example trajectory</div>
      <div>Follow the introdcutions below for to submit  exapmle job for either 5LOX or 5LOX-AKBA on Submit Job page</div>
      <div>Initially, upload the trajecory file 5LOXAPO_ca_traj.zip or 5LOXAKBA_ca_traj.zip:</div>
      <img src={upload_5lox} style={{border:'1px solid #000', width:"40%", display:"block"}}></img>
      <div>A force sampling tip will apear next, close it and select one sampling method. Here we choose first sampling strategy and submit the job.</div>
      <img src={example_submit_psh} style={{border:'1px solid #000', width:"30%", display:"block"}}></img>
      <div>Typically, NRIMD issues a job ID and user must wait for  job to  finish. However for  examples like 5LOX/5LOX_AKBA, NRIMD will promptly provide results.</div>
      <div style={{fontWeight:"bold"}}>3. Get the result</div>
      <div>As with the example 5LOX, users can check the job status On the Get Result page using the Job ID or bookmark with https://nrimd.luddy.iupui.edu/result/ + Job ID</div>
      <div>Let's analyze the  results of 5LOX.</div>
      <div> In the results genereated below (user submit job of 5LOXAPO_ca_traj.zip),
        the left heatmap demonstrates the inferred interactions between these residues from the NRIMD model. 
        The colors indicates the strength of the interaction: Dark colors msean strong interaction, while lighter colors means indicate weaker ones. 
        Users can adjust the parameter below to select customized threshold.</div>
        <div>The subsequent picture shows the allosteric path from the allosteric binding site (R101, represented as site 97 here due to the sample) to active site (F177 and Y181, represented as site 175 here).
          User can obtain the paths from the allosteric source residue to the allosteric target residue, 
          and also can reset it and get the real-time results. And the pathways can also be visualized on the left, where user can set protein and pathway style to to create appealing figures.</div>
        <img src={result_nri_5loxapo} style={{width:"60%", border:"1px solid gray"}}></img>
      <div>Similarly, we can analyze 5LOXAKBA (5LOX containing the inhibitor AKBA, user submitted job of 5LOXAKBA_ca_traj.zip) using NRIMD.</div>
        <img src={result_nri_5loxakba} style={{width:"60%", border:"1px solid gray"}}></img>
          <div>we can find there is almost no interaction between the n-terminal regulatory domain and the C-terminal catalysis in the absence of allosteric inhibitor binding, 
            but there is a significant interaction after addition. 
            Moreover, from the allosteric pathway, AKBA binding significantly increased the allosteric pathway 
            from binding site to active site. 
            These results seem to be consistent with speculation, indicating that NRIMD can effectively detect the allosteric effects of proteins.</div>
      </>,
      style: panelStyle,
    },
  ];
  return (
    <div style={{margin:"2vh 2%"}}>
      <div style={{...text_font,width:"100%",margin:"2vh 0"}}> Here we provide two examples of how to use NRIMD Webserver: </div>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              contentBg:""
            },
          },
        }}
      >
          <Collapse defaultActiveKey={[]} onChange={onChange} expandIconPosition={"end"} items={items} ghost={false} accordion={true} style={{...text_font, fontWeight:"-moz-initial",fontSize:"1.8vh",}}/>
      </ConfigProvider>
    </div>
  );
};
export default Example;
