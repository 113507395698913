// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[9].use[1]!../../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nrimd_menu .ant-menu-item {
  height: 6.5vh;
  line-height: 6.5vh;
}
`, "",{"version":3,"sources":["webpack://./src/nrimd/assets/lesses/app.less"],"names":[],"mappings":"AAIA;EAEI,aAAA;EACA,kBAAA;AAHJ","sourcesContent":["@import '~antd/dist/reset.css';\n\n\n\n.nrimd_menu{\n  .ant-menu-item{\n    height: 6.5vh;\n    line-height: 6.5vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
