import React, { useState } from 'react';
import { Tabs, Form, Button, Upload, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import amber from './assets/images/convert/amber.jpg';
import gromacs from './assets/images/convert/gromacs.png';
import namd from './assets/images/convert/namd.gif';
import charmm from './assets/images/convert/charmm.png';
import lammps from './assets/images/convert/lammps.png';
import desmond from './assets/images/convert/desmond.png';
import openmm from './assets/images/convert/openmm.png';

const { TabPane } = Tabs;
const VALID_TRAJ_FILE_SIZE = 50

const UploadTop = (props) => {
  const strucHandleChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        props.setTopologyFilePath(file.response.TopPath);
      }
      return file;
    });
    props.setStrucFileList(newFileList);
  };
  const strucProps = { action: '/api/nrimd/convert/upload_top/', onChange: strucHandleChange, multiple: false, fileList: props.strucFileList,
    beforeUpload: (file, fileList) => {
        // const fileType = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();  
        // if (fileType !== '.pdb' && fileType !== '.zip') {
        //     message.error('only support pdb and zip format')
        //     const index = fileList.indexOf(file)
        //     fileList.splice(index, 0);
        //     return false
        // }     
        const islt5M =  file.size / 1024 / 1024 < 5;
        if (!islt5M) {
            message.error(`topology size should be less than 5MB`)
            const index = fileList.indexOf(file)
            fileList.splice(index, 0)
            props.setStrucFileList([])
            return false
        }
      },
  };
  return (
    <>
      <Form name="complex-form" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
        <Form.Item name="StructureFile" label={props.label} extra={'File format: ' + props.extra}>
          <Upload {...strucProps}>
            <Button icon={<UploadOutlined />}>Upload</Button> &nbsp;&nbsp;&nbsp;
            <a href={'/api/nrimd/convert/download_example/' + props.download_href + '/'} onClick={(e) => { e.stopPropagation() }}>example</a>
          </Upload>
        </Form.Item>
      </Form>
    </>
  );
};

const UploadTraj = (props) => {
  const strucHandleChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        props.setCoordinateFilePath(file.response.TrajPath);
      }
      return file;
    });
    props.setStrucFileList(newFileList);
  };
  const strucProps = { action: '/api/nrimd/convert/upload_traj/', onChange: strucHandleChange, multiple: false, fileList: props.strucFileList,
    beforeUpload: (file, fileList) => {
        // const fileType = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();  
        // if (fileType !== '.pdb' && fileType !== '.zip') {
        //     message.error('only support pdb and zip format')
        //     const index = fileList.indexOf(file)
        //     fileList.splice(index, 0);
        //     return false
        // }     
        const islt5M =  file.size / 1024 / 1024 < VALID_TRAJ_FILE_SIZE;
        if (!islt5M) {
            message.error(`trajectory size should be less than ${VALID_TRAJ_FILE_SIZE}MB`)
            const index = fileList.indexOf(file)
            fileList.splice(index, 0)
            props.setStrucFileList([])
            return false
        }
      },
  };
  return (
    <>
      <Form name="complex-form" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
        <Form.Item name="TrajFile" label={props.label} extra={'File format: ' + props.extra}>
          <Upload {...strucProps}>
            <Button icon={<UploadOutlined />}>Upload</Button> &nbsp;&nbsp;&nbsp;
            <a href={'/api/nrimd/convert/download_example/' + props.download_href + '/'} onClick={(e) => { e.stopPropagation() }}>example</a>
          </Upload>
        </Form.Item>
      </Form>
    </>
  );
};

const Convert = () => {
  const [currentTab, setCurrentTab] = useState('1');
  const [topologyFilePath, setTopologyFilePath] = useState(null);
  const [coordinateFilePath, setCoordinateFilePath] = useState(null);
  const [outPDBFilePath, setOutPDBFilePath] = useState(null);
  const [loading, setLoading] = useState(false);

  const [amberTopFileList, setAmberTopFileList] = useState([]);
  const [amberTrajFileList, setAmberTrajFileList] = useState([]);
  const [gromacsTopFileList, setGromacsTopFileList] = useState([]);
  const [gromacsTrajFileList, setGromacsTrajFileList] = useState([]);
  const [namdTopFileList, setNamdTopFileList] = useState([]);
  const [namdTrajFileList, setNamdTrajFileList] = useState([]);
  const [charmmTopFileList, setCharmmTopFileList] = useState([]);
  const [charmmTrajFileList, setCharmmTrajFileList] = useState([]);
  const [lammpsTopFileList, setLammpsTopFileList] = useState([]);
  const [lammpsTrajFileList, setLammpsTrajFileList] = useState([]);
  const [openmmTopFileList, setOpenmmTopFileList] = useState([]);
  const [openmmTrajFileList, setOpenmmTrajFileList] = useState([]);
  const [desmondTopFileList, setDesmondTopFileList] = useState([]);
  const [desmondTrajFileList, setDesmondTrajFileList] = useState([]);

  const resetForm = () => {
    setTopologyFilePath(null);
    setCoordinateFilePath(null);
    setOutPDBFilePath(null);
    setLoading(false);

    switch (currentTab) {
      case '1':
        setAmberTopFileList([]);
        setAmberTrajFileList([]);
        break;
      case '2':
        setGromacsTopFileList([]);
        setGromacsTrajFileList([]);
        break;
      case '3':
        setNamdTopFileList([]);
        setNamdTrajFileList([]);
        break;
      case '4':
        setCharmmTopFileList([]);
        setCharmmTrajFileList([]);
        break;
      case '5':
        setLammpsTopFileList([]);
        setLammpsTrajFileList([]);
        break;
      case '6':
        setOpenmmTopFileList([]);
        setOpenmmTrajFileList([]);
        break;
      case '7':
        setDesmondTopFileList([]);
        setDesmondTrajFileList([]);
        break;
      default:
        break;
    }
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const ConvertButton = (props) => {
    return (
      <Button type="primary" htmlType="submit" disabled={!coordinateFilePath || !topologyFilePath} onClick={() => {
        setLoading(true);
        axios.post('/api/nrimd/convert/convert/', {
          topologyFile: topologyFilePath,
          coordinateFile: coordinateFilePath,
          conversionType: props.traj_type,
        })
        .then(response => {
            console.log(response.data);
            if(response.data.status=='success'){
                setOutPDBFilePath(response.data.CaTrajPDBPath);
                message.success('File conversion completed');
            }else{
                message.error(response.data.error);
            }
          })
          .catch(error => {
            message.error('File conversion failed');
          })
          .finally(() => {
            setLoading(false);
          });
      }}>
        Convert
      </Button>
    );
  };

  const DownloadButton = () => {
    return (
      <>
        <a href={'/api/nrimd/convert/download/' + outPDBFilePath + '/'}>
          <Button type="primary">
            Download
          </Button>
        </a>
        &nbsp;&nbsp;&nbsp;
        <Button  type="primary" onClick={resetForm}>
          Reset
        </Button>
      </>
    );
  };

  return (
    <Spin spinning={loading}>
      <Tabs defaultActiveKey="1" centered onChange={handleTabChange} style={{}}>
        <TabPane tab="AMBER" key="1" style={{ width: "60%", marginLeft: "20%" }}>
          <img src={amber} alt="" width={'50%'} style={{ marginLeft: "25%" }}></img>
          <div style={{ marginBottom: "2vh" }}>
            <a href='https://ambermd.org/AmberMD.php'>Amber</a> is a suite of biomolecular simulation programs. It began in the late 1970's, and is maintained by an active development community. 
            The format for trajectory is nc (Network Common Data Form), mdcrd (ASCII trajectory file format), crd (Binary trajectory file format) and 
            rst7/ncrst (restart file). For converting Amber trajectory to PDB format as NRIMD required, user should upload topology file in prmtop or parm7 format.
          </div>
          <UploadTraj setCoordinateFilePath={setCoordinateFilePath} download_href={'amber_traj'} label={'Amber Trajectory File'} extra={'nc, mdcrd, crd, ncrst, rst7'} setStrucFileList={setAmberTrajFileList} strucFileList={amberTrajFileList} />
          <UploadTop setTopologyFilePath={setTopologyFilePath} download_href={'amber_top'} label={'Amber Topology File'} extra='prmtop, parm7' setStrucFileList={setAmberTopFileList} strucFileList={amberTopFileList} />
          <div style={{ textAlign: "center" }}>
            {outPDBFilePath == null ?
              <ConvertButton traj_type='amber' />
              :
              <DownloadButton />}
          </div>
        </TabPane>
        <TabPane tab="GROMACS" key="2" style={{ width: "60%", marginLeft: "20%" }}>
          <img src={gromacs} alt="" width={'30%'} style={{ marginLeft: "35%" }}></img>
          <div>
            <a href='https://www.gromacs.org/'>GROMACS</a> is a free and open-source software suite for high-performance molecular dynamics and output analysis. 
            The format for trajectory is xtc. For converting GROMACS trajectory to PDB format as NRIMD required, user should upload topology file in gro format
          </div>
          <UploadTraj setCoordinateFilePath={setCoordinateFilePath} download_href={'gromacs_traj'} label={'GROMACS Trajectory File'} extra={'xtc'} setStrucFileList={setGromacsTrajFileList} strucFileList={gromacsTrajFileList} />
          <UploadTop setTopologyFilePath={setTopologyFilePath} download_href={'gromacs_top'} label={'GROMACS Topology File'} extra='gro' setStrucFileList={setGromacsTopFileList} strucFileList={gromacsTopFileList} />
          <div style={{ textAlign: "center" }}>
            {outPDBFilePath == null ?
              <ConvertButton traj_type='gromacs' />
              :
              <DownloadButton />}
          </div>
        </TabPane>
        <TabPane tab="NAMD" key="3" style={{ width: "60%", marginLeft: "20%" }}>
          <img src={namd} alt="" width={'30%'} style={{ marginLeft: "35%" }}></img>
          <div>
            <a href='https://www.ks.uiuc.edu/Research/namd/'>NAMD</a>, recipient of a 2002 Gordon Bell Award, a 2012 Sidney Fernbach Award, and a 2020 Gordon Bell Prize, 
            is a parallel molecular dynamics code designed for high-performance simulation of large biomolecular systems. 
            The format for trajectory is dcd. For converting NAMD trajectory to PDB format as NRIMD required, user should upload structure file in psf format
          </div>
          <UploadTraj setCoordinateFilePath={setCoordinateFilePath} download_href={'namd_traj'} label={'NAMD Trajectory File'} extra={'dcd'} setStrucFileList={setNamdTrajFileList} strucFileList={namdTrajFileList} />
          <UploadTop setTopologyFilePath={setTopologyFilePath} download_href={'namd_top'} label={'NAMD Topology File'} extra='psf' setStrucFileList={setNamdTopFileList} strucFileList={namdTopFileList} />
          <div style={{ textAlign: "center" }}>
            {outPDBFilePath == null ?
              <ConvertButton traj_type='namd' />
              :
              <DownloadButton />}
          </div>
        </TabPane>
        <TabPane tab="CHARMM" key="4" style={{ width: "60%", marginLeft: "20%" }}>
          <img src={charmm} alt="" width={'30%'} style={{ marginLeft: "35%" }}></img>
          <div>
            <a href='https://www.charmm.org/'>CHARMM</a>, A molecular simulation program with broad application to many-particle systems with a comprehensive set of energy functions, a variety of enhanced sampling methods, 
            and support for multi-scale techniques including QM/MM, MM/CG, and a range of implicit solvent models. 
            The format for trajectory is dcd. For converting CHARMM trajectory to PDB format as NRIMD required, user should upload structure file in psf format
          </div>
          <UploadTraj setCoordinateFilePath={setCoordinateFilePath} download_href={'charmm_traj'} label={'CHARMM Trajectory File'} extra={'dcd'} setStrucFileList={setCharmmTrajFileList} strucFileList={charmmTrajFileList} />
          <UploadTop setTopologyFilePath={setTopologyFilePath} download_href={'charmm_top'} label={'CHARMM Topology File'} extra='psf' setStrucFileList={setCharmmTopFileList} strucFileList={charmmTopFileList} />
          <div style={{ textAlign: "center" }}>
            {outPDBFilePath == null ?
              <ConvertButton traj_type='charmm' />
              :
              <DownloadButton />}
          </div>
        </TabPane>
        <TabPane tab="LAMMPS" key="5" style={{ width: "60%", marginLeft: "20%" }}>
          <img src={lammps} alt="" width={'30%'} style={{ marginLeft: "35%" }}></img>
          <div>
            <a href='https://www.lammps.org/'>LAMMPS</a> is a classical molecular dynamics code with a focus on materials modeling. It's an acronym for Large-scale Atomic/Molecular Massively Parallel Simulator. 
            The format for trajectory is lammpstrj. 
          </div>
          <UploadTraj setCoordinateFilePath={setCoordinateFilePath} download_href={'lammps_traj'} label={'LAMMPS Trajectory File'} extra={'lammpstrj'} setStrucFileList={setLammpsTrajFileList} strucFileList={lammpsTrajFileList} />
          <UploadTop setTopologyFilePath={setTopologyFilePath} download_href={'lammps_top'} label={'LAMMPS Topology File'} extra='prmtop' setStrucFileList={setLammpsTopFileList} strucFileList={lammpsTopFileList} />
          <div style={{ textAlign: "center" }}>
            {outPDBFilePath == null ?
              <ConvertButton traj_type='lammps' />
              :
              <DownloadButton />}
          </div>
        </TabPane>
        <TabPane tab="OpenMM" key="6" style={{ width: "60%", marginLeft: "20%" }}>
          <img src={openmm} alt="" width={'30%'} style={{ marginLeft: "35%" }}></img>
          <div>
            <a href='https://openmm.org/'>OpenMM</a>, a high-performance toolkit for molecular simulation. Use it as an application, a library, or a flexible programming environment. 
            The format for trajectory is dcd or xtc. For converting OpenMM trajectory to PDB format as NRIMD required, user should upload structure file in pdb format
          </div>
          <UploadTraj setCoordinateFilePath={setCoordinateFilePath} download_href={'openmm_traj'} label={'OpenMM Trajectory File'} extra={'xtc, dcd'} setStrucFileList={setOpenmmTrajFileList} strucFileList={openmmTrajFileList} />
          <UploadTop setTopologyFilePath={setTopologyFilePath} download_href={'openmm_top'} label={'OpenMM Topology File'} extra='pdb' setStrucFileList={setOpenmmTopFileList} strucFileList={openmmTopFileList} />
          <div style={{ textAlign: "center" }}>
            {outPDBFilePath == null ?
              <ConvertButton traj_type='openmm' />
              :
              <DownloadButton />}
          </div>
        </TabPane>
        <TabPane tab="DESMOND" key="7" style={{ width: "60%", marginLeft: "20%" }}>
          <img src={desmond} alt="" width={'30%'} style={{ marginLeft: "35%" }}></img>
          <div>
            <a href='https://www.schrodinger.com/platform/products/desmond/'>DESMOND</a> is a GPU-powered high-performance molecular dynamics (MD) engine for simulating biological systems such as small protein, viral capsids, protein-ligand complexes, small molecules in mixed solvents, organic solids, and synthetic macromolecular complexes. 
            The format for trajectory is dtr. For converting DESMOND trajectory to PDB format as NRIMD required, user should upload structure file in pdb format
          </div>
          <UploadTraj setCoordinateFilePath={setCoordinateFilePath} download_href={'desmond_traj'} label={'DESMOND Trajectory File'} extra={'dtr'} setStrucFileList={setDesmondTrajFileList} strucFileList={desmondTrajFileList} />
          <UploadTop setTopologyFilePath={setTopologyFilePath} download_href={'desmond_top'} label={'DESMOND Topology File'} extra='pdb' setStrucFileList={setDesmondTopFileList} strucFileList={desmondTopFileList} />
          <div style={{ textAlign: "center" }}>
            {outPDBFilePath == null ?
              <ConvertButton traj_type='desmond' />
              :
              <DownloadButton />}
          </div>
        </TabPane>
      </Tabs>
    </Spin>
  );
};

export default Convert;
